import { registerApplication, start } from "single-spa";

registerApplication({
  name: "smarter-gallery",
  app: () => System.import("@qmarketing-smarter-gallery"),
  activeWhen: ["/"],
});

start({
  urlRerouteOnly: true,
});
